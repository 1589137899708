import React, { useState } from 'react';
import './contacto.css';
import img_facebook from '../../assets/facebook.svg'
import img_instagram from '../../assets/instagram.svg'
import linkToPdfAP  from '../../assets/AvisoPrivacidad/Aviso de Privacidad Integral.2023.12.15.pdf';
import linkToPdfTC  from '../../assets/AvisoPrivacidad/T&C.2024.01.18.pdf';

const Contacto = () => {
    // Estado para controlar la visibilidad
    const [visible, setVisible] = useState({ AP: false, TC: false });

    const handleAPClick = () => {
        setVisible({ AP: true, TC: false }); // Mostrar AP y ocultar TC
    };

    const handleTCClick = () => {
        setVisible({ AP: false, TC: true }); // Mostrar TC y ocultar AP
    };
    
    return (
        <section id='contacto' className='contact-section'>
            <h1>Contáctanos</h1>
            <h2>En nuestras redes sociales</h2>
            <div className='social-container'>
                <a href='https://www.facebook.com/IntellegniumDisenadores'>
                    <img src={img_facebook} target="_blank" alt="facebook" className='social-image' />
                </a>
                <a href='https://www.instagram.com/Intellegniumdisenadores'>
                    <img src={img_instagram} target="_blank" alt="facebook" className='social-image' />
                </a>
            </div>
            <div className='social-container'>
                <button onClick={handleAPClick} className='buttonAPTC buttonAPBKGC'>
                    Aviso de Privacidad
                </button>

                <button onClick={handleTCClick} className='buttonAPTC buttonTCBKGC'>
                    T&C
                </button>

            </div>
            
            {/* Mostrar el contenido según el estado */}
            {visible.AP && (
                <>
                    <h1 id="APTitle">Aviso de Privacidad</h1>
                    <div style={{ height: "100vh", width: "100vw" }} id="APframe">
                        <iframe
                            src={linkToPdfAP}
                            title="Aviso de Privacidad"
                            style={{ width: "100%", height: "90%" }}
                        ></iframe>
                    </div>
                </>
            )}

            {visible.TC && (
                <>
                    <h1 id="TCTitle">Términos y Condiciones</h1>
                    <div style={{ height: "100vh", width: "100vw" }} id="TCframe">
                        <iframe
                            src={linkToPdfTC}
                            title="Términos y Condiciones"
                            style={{ width: "100%", height: "90%" }}
                        ></iframe>
                    </div>
                </>
            )}
        </section>
    );
}

export default Contacto;